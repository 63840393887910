export const questions = () => {
  $(document).ready(function () {
    $(".answer").hide();
    $(".questions_answers_item").on("click", function () {
      var targetId = $(this).data("target");
      $(this).toggleClass("active");
      $("#" + targetId).slideToggle(300);
      $(".answer")
        .not("#" + targetId)
        .slideUp(300);
      $(".questions_answers_item").not(this).removeClass("active");
    });
    $(".questions_answers_item").first().click();
  });
};
