export const price = () => {
  $(document).ready(function () {
    $(".price_text_repiter").hide();
    $(".price_item").on("click", function () {
      var targetId = $(this).data("target");
      $(this).toggleClass("active");
      $("#" + targetId).slideToggle(300);
      $(".price_text_repiter")
        .not("#" + targetId)
        .slideUp(300);
      $(".price_item").not(this).removeClass("active");
    });
    $(".price_item").first().click();
  });
};
