export const services = () => {
  var swiper = new Swiper(".custom-slider", {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    simulateTouch: false,
    allowTouchMove: false,
  });
};
