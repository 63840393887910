import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
import { modal } from "./modules/modal.js";
import { reviews } from "./modules/reviews.js";
import { star } from "./modules/star.js";
import { price } from "./modules/price.js";
import { doctors } from "./modules/doctors.js";
import { services } from "./modules/services.js";
import { slider } from "./modules/slider.js";
import { questions } from "./modules/questions.js";
import { popup } from "./modules/popup.js";
import { popups } from "./modules/popups.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  slider();
  reviews();
  modal();
  star();
  modals();
  price();
  services();
  popup();
  popups();
  doctors();
  questions();
});
