export const star = () => {
  const formRaitingList = document.querySelector(".rating");
  if (formRaitingList) {
    const formRaitingItems = document.querySelectorAll(".stars");
    formRaitingItems.forEach((formRaitingItem) => {
      let radioButton = formRaitingItem.querySelector("input");
      let radioButtonValue;
      formRaitingItem.addEventListener("mouseover", (el) => {
        radioButtonValue = radioButton.value;
        for (let i = 0; i < radioButtonValue; i += 1) {
          if (!formRaitingItems[i].classList.contains("hover-active"))
            formRaitingItems[i].classList.add("hover-active");
        }
      });
      formRaitingItem.addEventListener("mouseout", (el) => {
        for (let i = 0; i < radioButtonValue; i += 1) {
          if (formRaitingItems[i].classList.contains("hover-active"))
            formRaitingItems[i].classList.remove("hover-active");
        }
      });
      radioButton.addEventListener("change", (radioButtonEl) => {
        let targetValue = radioButtonEl.target.value;
        formRaitingList.dataset.id = targetValue;
        let dataList = formRaitingList.dataset.id;
        for (let i = 0; i < formRaitingItems.length; i += 1) {
          if (i >= dataList) formRaitingItems[i].classList.remove("active");
          else if (!formRaitingItems[i].classList.contains("active"))
            formRaitingItems[i].classList.add("active");
        }
      });
    });
  }
};
