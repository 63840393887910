export const reviews = () => {
  var swiper = new Swiper(".myswiperf", {
    slidesPerView: 1,
    spaceBetween: 50,
    breakpoints: {
      968: {
        slidesPerView: 2,
      },
      480: {
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: ".swiper-buttons-next",
      prevEl: ".swiper-buttons-prev",
    },
  });
};
